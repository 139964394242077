.card-block {
	box-shadow: 0 10px 26px 0 rgba($c4, .1);
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	-ms-border-radius: 7px;
	-o-border-radius: 7px;
	border-radius: 7px;
	margin-bottom: 20px;

	.ant-card-head-title {
		font-size: 20px;
		color: $c1;
	}
}