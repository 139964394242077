.companyColors {
	float: left;
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	.companyColorCol {
		margin-right: 40px;
		display: flex;
		flex-direction: column;

		> span {
			margin-bottom: 5px;
			color: rgba(0, 0, 0, 0.85);
		}
	}
}