@import 'vars';

.wrapper-view {
	padding: 24px 50px;

	.wrapper-inset {
		background: white;
		padding: 40px;
		box-shadow: 0 10px 16px 0 rgba($c4, .05);
		-webkit-border-radius: 7px;
		-moz-border-radius: 7px;
		-ms-border-radius: 7px;
		-o-border-radius: 7px;
		border-radius: 7px;

		h1.title {
			color: $c1;
		}

		h3.title {
			color: $c2;
			font-size: 20px;
			border-bottom: 1px solid #f1f1f1;
			padding-bottom: 20px;
			margin-bottom: 20px;
		}

		.wrapper-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;
		}
	}
}

.main-wrapper {
	.ant-layout {
		.ant-layout-content {
			min-height: auto;
		}
	}
}

.loading-wrapper-inset {
	float: left;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 100px;
}

.loading-view {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.pageLoaderWrapper {
	float: left;
	width: 100%;
	text-align: center;
	padding: 100px;
}