.vcard {
	position: absolute;
	right: 0px;
	bottom: 0px;
	top: 0px;
	left: 0px;
	display: flex;

	@media all and (max-width: 999px) {
	    position: static;
	}
	
	.hero {
		width: 50%;
		height: 100%;
		background: url('../images/login-hero.svg') center center no-repeat;
		background-size: cover !important;

		@media all and (max-width: 999px) {
		    width: 100%;
		    height: 300px;
		}
	}

	.vcard-wrapper {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		@media all and (max-width: 999px) {
		    position: static;
		    padding: 20px;
		    padding-top: 30px;
		}

		.vcard-block {
			background: #FFFFFF;
			box-shadow: 0 2px 54px 0 rgba(0,0,0,0.18);
			width: 100%;
			max-width: 680px;
			display: flex;
			flex-direction: column;

			.company-logo {
				border-top: 1px solid #e5e5e5;
				text-align: center;
				padding: 50px;

				img {
					max-width: 250px;
					max-height: 200px;
					width: auto;
					height: auto;
				}
			}

			.download {
				border-top: 1px solid #e5e5e5;
				padding: 30px;
				text-align: center;

				@media all and (max-width: 999px) {
				    padding: 10px;
				}

				a {
					background: $c1;
					color: white;
					font-weight: bold;
					text-transform: uppercase;
					padding: 15px 30px;
					-webkit-border-radius: 3px;
					-moz-border-radius: 3px;
					-ms-border-radius: 3px;
					-o-border-radius: 3px;
					border-radius: 3px;

					@media all and (max-width: 999px) {
					    float: left;
					    width: 100%;
					}

					&:hover {
						opacity: .8;
					}
				}
			}

			.location-social {
				float: left;
				width: 100%;
				display: flex;

				@media all and (max-width: 999px) {
				    flex-direction: column;
				    padding: 30px;
				}

				.col {
					padding: 30px;
					flex: 1;

					@media all and (max-width: 999px) {
					    padding: 0px;
					    margin-bottom: 10px;
					}

					h3 {
						color: #9C9092;
					}

					address {
						color: #9C9092;
					}

					.social-list {
						width: 100%;
						display: flex;

						a {
							margin-right: 15px;
							width: 20px;
							text-align: center;

							&:hover {
								opacity: .5;
							}
						}
					}
				}
			}

			.basic-info {
				padding: 30px;
				border-bottom: 1px solid #f1f1f1;
				float: left;
				width: 100%;
				display: flex;
				flex-direction: column;

				h1 {
					color: $c1;
					font-weight: bold;
					margin-bottom: 5px;
				}

				h2 {
					color: #9C9092;
					font-size: 17px;
					font-weight: normal;
					text-transform: uppercase;
				}

				.email {
					color: $c2;
					font-weight: bold;
					font-size: 17px;
				}

				.url {
					font-weight: bold;
					color: #9C9092;
					font-size: 17px;

					&:hover {
						color: $c3;
					}
				}

				.phones {
					float: left;
					width: 100%;
					display: flex;

					@media all and (max-width: 999px) {
					    flex-direction: column;
					    padding: 20px 0px;
					}

					.block {
						margin: 20px 0px;
						flex: 1;
						margin-right: 20px;
						display: flex;

						@media all and (max-width: 999px) {
						    margin: 0;
						    width: 100%;
						}

						.block-wrapper {
							display: flex;
							flex-direction: column;
							margin-left: 20px;

							span {
								font-weight: bold;
								text-transform: uppercase;
								color: #D7BCC0;
								line-height: 1;
							}

							h4 {
								color: $c2;
								font-weight: bold;
								font-size: 20px;
							}
						}

						&:last-child {
						    margin-right: 0;

						}
					}
				}
			}

		}


		.vcard-loading {
			width: 100%;
			text-align: center;
			padding: 100px 0px;




		}

		
	}

}