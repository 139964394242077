.login {
	position: absolute;
	right: 0px;
	bottom: 0px;
	top: 0px;
	left: 0px;
	display: flex;
	
	.hero {
		width: 50%;
		height: 100%;
		background: url('../images/login-hero.svg') center center no-repeat;
		background-size: cover !important;
	}

	.login-wrapper {
		height: 100%;
		flex: 1;
		padding: 40px;
		display: flex;
		align-items: center;
		justify-content: center;

		.login-inset {
			width: 100%;
			max-width: 500px;

			.login-form-button {
			  width: 100%;
			  margin-bottom: 10px;
			}

			.login-form-forgot {
			  float: right;
			}

			.logo-wrapper {
				text-align: center;
				margin-bottom: 30px;

				img {
					max-width: 300px;
				}
			}
		}
	}

}