.colorPickerCover {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
}

.colorPicker {
	
	position: relative;

	.colorPickerHolder {
		position: absolute;
		z-index: 10;
		top: 40px;
	}

	.colorPickerCurrent {

		width: 100%;
		display: flex;
		align-items: center;

		.colorPickerCurrentColor {
			width: 40px;
			height: 40px;
			border: 1px solid #e5e5e5;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			-ms-border-radius: 5px;
			-o-border-radius: 5px;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			&:hover {
				opacity: .7;
			}

			span {
				width: 80%;
				height: 80%;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				-ms-border-radius: 3px;
				-o-border-radius: 3px;
				border-radius: 3px;
			}
		}
	
		.colorPickerCurrentCode {
			font-weight: bold;
			margin-left: 10px;
			cursor: pointer;
		}
	}
}