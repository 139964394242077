.biz-card-preview {
	display: inline-block;
	width: 100%;
	background: #FEF8F8;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	border-radius: 10px;
	height: 500px;

	.biz-card-preview-tabs {
		float: left;
		width: 100%;
		text-align: center;
		padding: 20px 0px;
		border-bottom: 1px solid #F1F1F1;
	}

	.biz-card-preview-tab {
		float: left;
		width: 100%;
		padding: 20px;
		text-align: center;

		@media all and (max-width: 1500px) {
		    padding: 10px;
		}

		.card-holder {
			display: inline-block;
			box-shadow: 0 10px 16px 0 rgba(81, 9, 50, 0.05);
		}

		img {
			max-width: 100%;
			height: auto;
		}
	}

	.biz-card-action-button {
		float: left;
		width: 100%;
		text-align: center;
		padding: 20px 0px;
		border-top: 1px solid #F1F1F1;
		margin-top: 20px;
	}
}