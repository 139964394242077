.image-selecter {
	float: left;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.image-preview {
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		-ms-border-radius: 3px;
		-o-border-radius: 3px;
		border-radius: 3px;
		border: 2px solid rgba($c1, .1);
		padding: 10px;
		margin-bottom: 20px;

		img {
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			-ms-border-radius: 4px;
			-o-border-radius: 4px;
			border-radius: 4px;
			max-width: 200px;
			height: auto;
		}
	}

	.button-holder {
		position: relative;

		&:hover {
			cursor: pointer;
			button {
				background: $c1;
			}
		}

		input {
			position: absolute;
			top: 0px;
			opacity: 0;
		}

		button {
			background: $c2;
			color: white;
			padding: 10px;
			border: 0px;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			-ms-border-radius: 4px;
			-o-border-radius: 4px;
			border-radius: 4px;
		}
	}
}