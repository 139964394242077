@import 'vars';

.dashboard-view, #__next {
	height: 100vh;
}

.dashboard-view {
	@import 'dashboard/defaults';
	@import 'dashboard/sider';
	@import 'dashboard/header';
	@import 'dashboard/cards';
	@import 'dashboard/pages';
}

.dashboard-greentings {
	float: left;
	width: 100%;
	margin-bottom: 30px;
	margin-top: 30px;
	font-size: 20px;
	line-height: 1;
	text-align: center;

	span {
		color: #BA9DAD;
		opacity: .6;
	}

	h2 {
		color: rgba($c4, .4) !important;

		strong {
			color: $c1;
		}
	}

	strong {
		float: left;
		width: 100%;
	}
}

.dashboard-image {
	width: 100%;
	text-align: center;
	padding: 40px 0px;

	img {
		width: 100%;
		max-height: 500px;
	}
}