.ant-form-item-label {
	line-height: 24px;
}

.ant-form-item {
	margin-bottom: 10px;
}

.ant-tabs-no-overflow {
	overflow: inherit;
}

.sketch-picker {
	border: 1px solid #e5e5e5;
	box-shadow: 0 12px 37px 0 rgba(0,0,0,0.14) !important;
}