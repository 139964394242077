@import 'vars';

.sign-preview {
	
	margin-top: 40px;
	margin-bottom: 50px;

	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
	border-radius: 6px;

	// position: -webkit-sticky;
	// position: sticky;
	// top: 10px;

	img {
		max-width: 100%;
		height: auto;
	}
}


.waitForPreview {
	display: flex;
	flex-direction: column;
	align-items: center;

	img {
		margin-bottom: 20px;
	}
}

.code {
	code {
		word-break: break-word;
	}
}