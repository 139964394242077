.sider {
	background: $c1;
	//background-image: linear-gradient(-180deg, $c1 0%, $c3 100%);
	background: url('../images/sider-background.svg');
	background-size: auto 100% !important;
	color: white;
	overflow: hidden;

	.logo {
		padding: 35px;
		margin: 20px 0px;
		border-bottom: 1px solid rgba(white, .1);

		img {
			max-width: 100%;
			height: auto;
		}
	}

	.end-view {
		position: absolute;
		bottom: 25px;
		padding-left: 10px;

		a {
			font-size: 12px;
			margin-right: 10px;
			color: rgba(white, .3);

			&:hover {
				color: white;
			}
		}
	}
	
	.main-menu {
		background: none;
		border: 0px;
		color: white;

		* {
			color: white;
		}

		.ant-menu-submenu > .ant-menu {
			background: none;
			color: white;

		}

		.ant-menu-item > a {
			display: inline-block;
		}

		.ant-menu-item-selected {
			color: $c1;

			span, i, a {
				color: $c1;
			}



			i {
				svg {
					color: $c1;
					path {
						color: $c1;
					}
				}
			}
		}

		.ant-menu-submenu-arrow {
			color: white !important;

			&:before, &:after {
				background: white !important;
			}
		}

		.ant-menu-submenu-open {
			> ul {
				background: rgba($c4, .1) !important;
			}
		}


	}

}