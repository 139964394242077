.swal2-container.swal2-shown {
	background: rgba(white, .2) !important;
}

.swal2-styled.swal2-confirm {
	background: $c1 !important;
}

.swal2-title {
	color: $c1 !important;
}

.swal2-popup {
	background: #FFFFFF;
	box-shadow: 0 2px 54px 0 rgba(0,0,0,0.18);
}