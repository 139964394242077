.footer-view {
	float: left;
	width: 100%;
	display: flex;
	justify-content: space-between;
	font-size: 12px;

	.version {
		opacity: .5;
		font-weight: bold;
	}
}