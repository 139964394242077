@import 'vars';

.header-view {
	float: left;
	width: 100%;
	display: flex;

	.search-view {
		flex: 1;
		margin-right: 30px;
		position: relative;

		.search-results {
			position: absolute;
			right: 0px;
			left: 0px;
			top: 50px;
			z-index: 100;

			.search-results-view {
				float: left;
				width: 100%;
				line-height: 1;
				background: #FFFFFF;
				box-shadow: 0 1px 4px 0 rgba(0,0,0,0.07), 0 8px 44px 0 rgba(0,0,0,0.09);
				background: white;
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				-ms-border-radius: 5px;
				-o-border-radius: 5px;
				border-radius: 5px;
				margin-top: 10px;
				padding: 20px;

				.search-section-title {
					color: $c1;
					font-weight: bold;
					font-size: 18px;
					margin-bottom: 10px;
				}

				.search-section-children {
					float: left;
					width: 100%;

					.search-section-children-item {
						float: left;
						width: 100%;
						border-bottom: 1px solid #f1f1f1;

						&:last-child {
						    border-bottom: 0;
						}

						a {
							color: #555;
							font-size: 15px;
							float: left;
							width: 100%;
							padding: 13px 0px;
							-webkit-border-radius: 4px;
							-moz-border-radius: 4px;
							-ms-border-radius: 4px;
							-o-border-radius: 4px;
							border-radius: 4px;

							&:hover {
								background: rgba($c1, .03);
							}
						}
					}
				}
			}
		}
	}

	.user-info {
		display: flex;
		align-items: center;

		.user-name {
			margin-right: 10px;
			strong {
				color: $c2;
			}
		}

		.user-avatar {
			border: 4px solid rgba($c1, .2);
			height: 48px;
			cursor: pointer;
			
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			-ms-border-radius: 50%;
			-o-border-radius: 50%;
			border-radius: 50%;

			.ant-avatar {
				display: inline-block;
				vertical-align: top;
			}
		}
	}
}